import React from "react";
import storage from "../../../../Storage";
import socket from "../../../../Socket";
import {__, encode, wait, PAYBILL, MIN_DEPOSIT} from "../../../../Helper";
import C from "../../../../Constant";
import {Stack} from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Button from "@mui/material/Button";
import PaymentsIcon from "@mui/icons-material/Payments";
import Typography from "@mui/material/Typography";

class Depositing extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            amount: MIN_DEPOSIT,
            phone: storage.getKey('phone'),
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    submit = (e) => {
        e.preventDefault();

        socket.emit(C.DEPOSIT, encode({
            phone: this.state.phone,
            amount: this.state.amount,
        }));

        wait(1000).then(() => {
            this.setState({submitted: true});
        })
    }

    handleChangeAmount = (e) => {
        let val = e.target.value;
        this.setState({amount: val});
    }

    render() {
        return (
            <form onSubmit={(e) => {
                this.submit(e)
            }}>
                <Stack>
                    <div className={'custom-card m-2'}>
                        <span className={'text-white custom-label'}>Amount&nbsp;&nbsp;</span>
                        <input required placeholder={'-- e.g. 100 --'} type={"number"} id={"mobile-number"} min={MIN_DEPOSIT} value={this.state.amount}
                               onChange={this.handleChangeAmount}
                               className={"text-strong form-control input-number custom-input"} autoComplete={'off'} name={"amount"}/>
                        <AttachMoneyIcon className={'text-white'}/>
                    </div>
                    <Button type={'submit'} size={'large'} variant="contained" className={'custom-button m-2'} sx={{m: 2}} disabled={false}><PaymentsIcon/>&nbsp;Submit</Button>
                    {this.state.submitted ?
                        <span style={{fontSize: '.8rem'}} className="text-success text-center"><div className="spinner-border spinner-border-sm" role="status"/>
                            &nbsp;Please Enter your PIN in the next Prompt</span>
                        : ''}

                    <Typography className={'m-2'} sx={{color: 'white'}} gutterBottom>
                        <dt className={'text-warning font-weight-bold'}>Instructions:</dt>
                        <span style={{textAlign: "left", color: 'white'}}>
                            &#10004; Enter the amount and Click <strong style={{color: 'red'}}>Submit</strong>.<br/>
                            &#10004; Enter your <strong style={{color: 'red'}}>PIN</strong> on your phone when prompted.
                        </span>
                        <br/><br/>
                        <dt className={'text-warning font-weight-bold'}>Limits:</dt>
                        Minimum amount: {MIN_DEPOSIT}/-<br/>
                        Maximum amount: 250,000/-<br/>
                        Daily Transaction Limit: 500,000/-
                    </Typography>
                </Stack>
            </form>
        );
    }
}

export default Depositing;