import Carousel from 'react-bootstrap/Carousel';
import {BANNER_DAY, BANNER_HOUR, Event} from "../../../Helper";
import CrasherHourTime from "./CrasherHourTime";
import React from "react";
import CrasherDayTime from "./CrasherDayTime";

function showBannerHour() {
    Event.emit('show_banner_hour');
}

function showBannerDay() {
    Event.emit('show_banner_day');
}

function BannerCarousal() {

    return (
        <Carousel>
            <Carousel.Item onClick={showBannerHour}>
                <img className={"d-block w-100"} style={{borderRadius: '4px 4px 0px 0px'}} src={BANNER_HOUR} alt="Crasher Hour"/>
                <div className={'w-100 custom-panel-light'} style={{borderRadius: '0px 0px 4px 4px', fontSize: '0.8em', paddingLeft: '5px'}}><CrasherHourTime/></div>
            </Carousel.Item>
            <Carousel.Item onClick={showBannerDay}>
                <img style={{borderRadius: '4px 4px 0px 0px'}} className="d-block w-100" src={BANNER_DAY} alt="Crasher Day"/>
                <div className={'w-100 custom-panel-light'} style={{borderRadius: '0px 0px 4px 4px', fontSize: '0.8em', paddingLeft: '5px'}}><CrasherDayTime/></div>
            </Carousel.Item>
        </Carousel>
    );
}

export default BannerCarousal;