import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Dropdown, Modal} from "react-bootstrap";
import coins from "../../../coins";
import socket from "../../../../Socket";
import storage from "../../../../Storage";
import {setCoin, gameCoin, setCredit} from "../../../../actions/gameCoin";
import {__, wait, decode, encode, forceSatoshiFormat, Event, COIN_TOKEN, formatAmount} from "../../../../Helper";
import C from "../../../../Constant";
import {NavLink} from "react-router-dom";
import Engine from "../../../Games/Crash/Engine";

class Credit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            token: storage.getKey('token'),
            coin: COIN_TOKEN,
            selectedCoin: COIN_TOKEN,
            credits: {},
            coins: [],
            different: [],
            credit: false,
            phone: storage.getKey('phone'),
            name: storage.getKey('name'),
        };
        this.selectCoin = this.selectCoin.bind(this);
    }

    componentDidMount() {
        //Get Redux Coin
        this.props.gameCoin();

        if (this.state.token !== null)
            socket.emit(C.CREDIT, encode({token: this.state.token, coin: this.state.coin}));

        socket.on(C.CREDIT, data => this.getCredit(decode(data)));
        socket.on(C.UPDATE_CREDIT, data => this.updateCredit(decode(data)));
    }

    updateAllCoins() {
        this.setState({coins: []});

        coins.forEach((item, key) => {
            var style = __.lowerCase(item.preffix) === __.lowerCase(this.state.selectedCoin) ? 'actc' : '';
            var value = forceSatoshiFormat(this.state.credits[__.lowerCase(item.preffix)]);
            var coin = <Dropdown.Item onClick={e => this.selectCoin(item.preffix)} key={key} className={"num-style " + style}>
                <div className="float-left">
                    <img src={'/assets/images/' + item.image} alt="coin" className={'mini-coin-7'}/>
                    {item.preffix}
                </div>
                <div className="float-right">{value}</div>
            </Dropdown.Item>;
            this.setState(state => ({coins: [coin, ...state.coins]}));
        });

    }

    getCredit(data) {
        let {credit} = data; //{kes: 9140.01, bonus: '0.00', demo: '0.00'}

        this.setState({credit: credit.kes});
        storage.setKey('credit', credit.kes);

        Engine.trigger.emit('update_bonus', credit.bonus);

        //Set for Redux
        this.props.setCredit(credit.kes);
    }

    updateCredit(data) {
        let credit = data.value;
        let coin = data.coin;
        let profit_bonus = data.profit_bonus;

        if (profit_bonus === 'undefined' || profit_bonus == null) {
            profit_bonus = '0';
        }

        let different;
        let arr;

        if (__.toNumber(credit) > __.toNumber(this.state.credit)) {
            different = __.toNumber(credit) - __.toNumber(this.state.credit);
            arr = {
                amount: different,
                color: 'text-success',
                my_bonus: profit_bonus
            }
        } else {
            different = __.toNumber(this.state.credit) - __.toNumber(credit);
            arr = {
                amount: different,
                color: 'text-danger',
                my_bonus: profit_bonus
            }
        }

        let check = forceSatoshiFormat(different);

        if (check.toString() !== '0.00000000') {
            this.setState(state => ({different: [arr, ...state.different]}));
        }

        this.setState({credit: forceSatoshiFormat(credit)});
        storage.setKey('credit', credit);
        this.updateParentCoin(__.lowerCase(coin), credit);

        //Set for Redux
        this.props.setCredit(forceSatoshiFormat(credit));
    }

    updateParentCoin(coin, amount) {
        this.setState({coins: []});

        coins.forEach((item, key) => {
            if (__.lowerCase(item.preffix) === coin) {
                const style = __.lowerCase(item.preffix) === __.lowerCase(this.state.selectedCoin) ? 'actc' : '';
                let update = <Dropdown.Item onClick={e => this.selectCoin(__.upperCase(coin))} key={key} className={"num-style " + style}>
                    <div className="float-left">
                        <img src={'/assets/images/' + item.image} alt="coin" className={'mini-coin-7'}/>
                        {item.preffix}
                    </div>
                    <div className="float-right">{forceSatoshiFormat(amount)}</div>
                </Dropdown.Item>;
                this.setState(state => ({coins: [update, ...state.coins]}));
            } else {
                const style = __.lowerCase(item.preffix) === __.lowerCase(this.state.selectedCoin) ? 'actc' : '';
                let value = forceSatoshiFormat(this.state.credits[__.lowerCase(item.preffix)]);
                let update = <Dropdown.Item onClick={e => this.selectCoin(item.preffix)} key={key} className={"num-style " + style}>
                    <div className="float-left">
                        <img src={'/assets/images/' + item.image} alt="coin" className={'mini-coin-7'}/>
                        {item.preffix}
                    </div>
                    <div className="float-right">{value}</div>
                </Dropdown.Item>;
                this.setState(state => ({coins: [update, ...state.coins]}));
            }
        });
    }

    selectCoin(name) {
        //Fix For Double selection
        if (storage.getKey('coin') === name) return;
        storage.setKey('coin', name);

        let credit = this.state.credits[__.lowerCase(name)];
        this.setState({coin: name, credit: credit, selectedCoin: name});

        //Set Coin For Redux
        this.props.setCoin(name);
        this.props.setCredit(credit);

        wait(200).then(() => {
            this.updateAllCoins();
            Event.emit('coin_changed');
        })
    }

    addDiff(data, i) {
        let id = 'id_' + Math.floor(Math.random() * 1000 + 1);

        wait(2000).then(() => {
            try {
                document.getElementById(id).classList.remove('frd');
                document.getElementById(id).classList.add('fadeOutDown');
            } catch (e) {
            }
            this.state.different.splice(i, 1);
        });

        return <li key={i} id={id} className={'text-nowrap list-inline w-100 text-right animated frd ' + data.color}>
            {(data.color === 'text-danger' ? '-' : '+')}{forceSatoshiFormat(data.amount, this.state.coin)}{(data.color === 'text-success' && data.my_bonus !== '0') ? ' +Bonus ' + data.my_bonus : ''}
        </li>;
    }

    open = () => {
        this.setState({show: !this.state.show});
    }

    showDeposit() {
        Event.emit('show_deposit');
    }

    render() {
        let {credit, different} = this.state;
        credit = formatAmount(credit);
        let diff = different.map((data, i) =>
            this.addDiff(data, i)
        );

        return (
            <div onClick={this.showDeposit}>
                <span style={{fontSize: '0.6rem', color: 'black'}}>KES</span>&nbsp;<span className={'text-white'} style={{fontWeight: "bold"}}>{credit}</span>
            </div>
        );
    }
}

Credit.propTypes = {
    setCoin: PropTypes.func.isRequired,
    coin: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin
});

export default connect(mapStateToProps, {setCoin, gameCoin, setCredit})(Credit);